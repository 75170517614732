import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-d9f837ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-grid" }
const _hoisted_2 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_3 = { for: "testType" }
const _hoisted_4 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_5 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_6 = { for: "name" }
const _hoisted_7 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_8 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_9 = { for: "specialist" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "dropdown-item" }
const _hoisted_13 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_14 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_15 = { for: "viewerSpecialists" }
const _hoisted_16 = { class: "dropdown-item" }
const _hoisted_17 = {
  key: 0,
  class: "p-field p-col-6 p-fluid"
}
const _hoisted_18 = { for: "date" }
const _hoisted_19 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_20 = {
  key: 1,
  class: "p-field p-col-6 p-fluid"
}
const _hoisted_21 = { for: "time" }
const _hoisted_22 = {
  key: 0,
  class: "p-invalid"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_InlineMessage = _resolveComponent("InlineMessage")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.showDialog) = $event)),
    modal: true,
    header: _ctx.modalLabel
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        type: "button",
        label: _ctx.$t('common.cancel'),
        class: "p-button p-component p-button-secondary p-button-medium",
        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showDialog = false))
      }, null, 8, ["label"]),
      _createVNode(_component_Button, {
        type: "button",
        disabled: _ctx.cgmCreationDisabled,
        label: _ctx.confirmButtonLabel,
        class: _normalizeClass([{ 'p-button-outlined p-disabled': _ctx.cgmCreationDisabled }, "p-button p-component p-button-info p-button-medium"]),
        onClick: _ctx.onSubmittForm
      }, null, 8, ["disabled", "label", "class", "onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('medicalTest.newTestModalMessage')), 1),
      _createElementVNode("form", null, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('medicalTest.type')) + "*", 1),
            _createVNode(_component_Dropdown, {
              id: "testType",
              modelValue: _ctx.newMedicalTestForm.testType,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newMedicalTestForm.testType) = $event)),
              required: "true",
              placeholder: _ctx.$t('medicalTest.select-type'),
              options: _ctx.medicalTestTypeOptions,
              "option-label": "label",
              "option-value": "value",
              disabled: _ctx.editMode,
              class: _normalizeClass({ 'p-invalid': _ctx.showValidation && !_ctx.validatedMedicalTestBaseForm.testType.valid }),
              onChange: _ctx.onTestTypeChange
            }, null, 8, ["modelValue", "placeholder", "options", "disabled", "class", "onChange"]),
            (_ctx.showValidation && !_ctx.validatedMedicalTestBaseForm.testType.valid)
              ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(_ctx.$t(`${_ctx.validatedMedicalTestBaseForm.testType.message}`)), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('medicalTest.name')) + "*", 1),
            _createVNode(_component_InputText, {
              id: "name",
              modelValue: _ctx.newMedicalTestForm.name,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newMedicalTestForm.name) = $event)),
              modelModifiers: { trim: true },
              required: "true",
              class: _normalizeClass({ 'p-invalid': _ctx.showValidation && !_ctx.validatedMedicalTestBaseForm.name.valid }),
              onInput: _ctx.onTestNameInput
            }, null, 8, ["modelValue", "class", "onInput"]),
            (_ctx.showValidation && !_ctx.validatedMedicalTestBaseForm.name.valid)
              ? (_openBlock(), _createElementBlock("small", _hoisted_7, _toDisplayString(_ctx.$t(`${_ctx.validatedMedicalTestBaseForm.name.message}`)), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('medicalTest.main-specialist')) + "*", 1),
            _createVNode(_component_Dropdown, {
              id: "specialist",
              modelValue: _ctx.newMedicalTestForm.specialistId,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newMedicalTestForm.specialistId) = $event)),
              required: "true",
              options: _ctx.specialistOptions,
              optionValue: "professionalId",
              placeholder: _ctx.$t('medicalTest.select-specialist'),
              class: _normalizeClass({ 'p-invalid': _ctx.showValidation && !_ctx.validatedMedicalTestBaseForm.specialistId.valid }),
              onChange: _ctx.onSpecialistChange
            }, {
              value: _withCtx((slotProps) => [
                (slotProps.value)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.specialistOptions?.find((specialist) => specialist.professionalId == slotProps.value)?.name) + " " + _toDisplayString(_ctx.specialistOptions?.find((specialist) => specialist.professionalId == slotProps.value)?.surname), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(slotProps.placeholder), 1))
              ]),
              option: _withCtx((slotProps) => [
                _createVNode(_component_Avatar, {
                  image: slotProps.option.picture,
                  class: "p-mr-2",
                  size: "small",
                  shape: "circle"
                }, null, 8, ["image"]),
                _createElementVNode("span", _hoisted_12, _toDisplayString(slotProps.option.name) + " " + _toDisplayString(slotProps.option.surname), 1)
              ]),
              _: 1
            }, 8, ["modelValue", "options", "placeholder", "class", "onChange"]),
            (_ctx.showValidation && !_ctx.validatedMedicalTestBaseForm.specialistId.valid)
              ? (_openBlock(), _createElementBlock("small", _hoisted_13, _toDisplayString(_ctx.$t(`${_ctx.validatedMedicalTestBaseForm.specialistId.message}`)), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.$t('medicalTest.viewer-specialists')), 1),
            _createVNode(_component_MultiSelect, {
              id: "viewerSpecialists",
              modelValue: _ctx.newMedicalTestForm.viewerSpecialistIds,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.newMedicalTestForm.viewerSpecialistIds) = $event)),
              options: _ctx.selectableSpecialistOptions,
              optionValue: "professionalId",
              placeholder: _ctx.$t('medicalTest.select-specialist'),
              class: "p-column-filter multiselect-custom"
            }, {
              value: _withCtx((slotProps) => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slotProps.value, (option) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: option.code,
                    class: "specialist-item-value"
                  }, _toDisplayString(_ctx.specialistOptions?.find((specialist) => specialist.professionalId == option)?.name) + " " + _toDisplayString(_ctx.specialistOptions?.find((specialist) => specialist.professionalId == option)?.surname), 1))
                }), 128)),
                (!slotProps.value || slotProps.value.length === 0)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(_toDisplayString(slotProps.placeholder), 1)
                    ], 64))
                  : _createCommentVNode("", true)
              ]),
              option: _withCtx((slotProps) => [
                _createVNode(_component_Avatar, {
                  image: slotProps.option.picture,
                  class: "p-mr-2",
                  size: "small",
                  shape: "circle"
                }, null, 8, ["image"]),
                _createElementVNode("span", _hoisted_16, _toDisplayString(slotProps.option.name) + " " + _toDisplayString(slotProps.option.surname), 1)
              ]),
              _: 1
            }, 8, ["modelValue", "options", "placeholder"])
          ]),
          (_ctx.newMedicalTestForm.testType == _ctx.MedicalTestTypeValue.CALORIMETRY)
            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                _createElementVNode("label", _hoisted_18, _toDisplayString(_ctx.$t('common.date')) + " *", 1),
                _createVNode(_component_Calendar, {
                  id: "date",
                  modelValue: _ctx.newMedicalTestForm.date,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.newMedicalTestForm.date) = $event)),
                  required: _ctx.newMedicalTestForm.testType == _ctx.MedicalTestTypeValue.CALORIMETRY,
                  class: _normalizeClass({ 'p-invalid': !_ctx.validatedCalorimetryForm.date.valid })
                }, null, 8, ["modelValue", "required", "class"]),
                (!_ctx.validatedCalorimetryForm.date.valid)
                  ? (_openBlock(), _createElementBlock("small", _hoisted_19, _toDisplayString(_ctx.$t(`${_ctx.validatedCalorimetryForm.date.message}`)), 1))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (_ctx.newMedicalTestForm.testType == _ctx.MedicalTestTypeValue.CALORIMETRY)
            ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.$t('common.hour')) + " *", 1),
                _createVNode(_component_Calendar, {
                  id: "hour",
                  modelValue: _ctx.newMedicalTestForm.hour,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.newMedicalTestForm.hour) = $event)),
                  required: _ctx.newMedicalTestForm.testType == _ctx.MedicalTestTypeValue.CALORIMETRY,
                  "time-only": true,
                  "hour-format": "24",
                  class: _normalizeClass({ 'p-invalid': !_ctx.validatedCalorimetryForm.hour.valid })
                }, null, 8, ["modelValue", "required", "class"]),
                (!_ctx.validatedCalorimetryForm.hour.valid)
                  ? (_openBlock(), _createElementBlock("small", _hoisted_22, _toDisplayString(_ctx.$t(`${_ctx.validatedCalorimetryForm.hour.message}`)), 1))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      (_ctx.displayInlineWarning)
        ? (_openBlock(), _createBlock(_component_InlineMessage, {
            key: 0,
            severity: "warn"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('medicalTest.cgm-limit')), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}